import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BiContent from "../components/bi-content"
import ContactBanner from "../components/contact-banner"
import ContactOptions from "../components/contact-options"
import SocialMedia from "../components/social-media"

import PlaceholderImage from "../assets/placeholders/placeholder-01@2x.png"

const mainHeaderContent = (
  <div>
    <p
      style={{
        marginBottom: "1rem",
        color: "black",
        fontWeight: 500,
        fontSize: "1.3em",
      }}
    >
      Pasa a decir hola :)
    </p>
    <p>
      <span className="w-bold">Oficina Co-working, Tec de Monterrey</span>
      <br />
      Av. General Ramón Corona No 2514, 4to Piso CEI.
      <br />
      Colonia Nuevo México, 45201.
      <br />
      Zapopan, Jalisco, México.
      <br />
      <span className="c-turquoise w-bold">estacion@meiquer.mx</span>
    </p>
    <SocialMedia dark />
  </div>
)

const mainHeaderTitle = (
  <h1 className="w-normal">
    <span className="c-turquoise w-bold">Juega</span> y conecta con{" "}
    <span className="w-bold">nosotros</span>
  </h1>
)

const customContactTitle = (
  <h2 className="w-normal">
    ¿Cómo <span className="s-italic">podemos</span>{" "}
    <span className="c-turquoise w-bold">jugar</span>{" "}
    <span className="s-italic">en</span> <span className="w-bold">equipo</span>?
  </h2>
)

const ContactoPage = props => (
  <Layout location={props.location}>
    <SEO title="Contacto" />
    <BiContent
      content={mainHeaderContent}
      title={mainHeaderTitle}
      image={PlaceholderImage}
    />
    <ContactBanner />
    <ContactOptions title={customContactTitle} hideBottom />
  </Layout>
)

export default ContactoPage
