import React from "react"
import PropTypes from "prop-types"

import "../styles/custom-input.scss"

const CustomInput = ({ label, type, name, placeholder, selectOptions }) => (
  <div className="field custom-input">
    <label className="label">{label}</label>
    {type === "select" ? (
      <div className="select">
        <select name={name} defaultValue="">
          <option value="" disabled>
            {placeholder}
          </option>
          {selectOptions.map((option, idx) => (
            <option key={idx} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    ) : (
      <div className="control">
        <input
          aria-label="Action input"
          className="input"
          type={type}
          name={name}
          placeholder={placeholder}
        />
      </div>
    )}
  </div>
)

CustomInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(["text", "email", "tel", "select"]),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  selectOptions: PropTypes.array,
}

CustomInput.defaultProps = {
  label: "Input",
  type: "text",
  name: "",
  placeholder: "input",
  selectOptions: [],
}

export default CustomInput
