import React from "react"
import PropTypes from "prop-types"

import "../styles/bi-content.scss"

const BiContent = ({ image, content, title }) => (
  <section className="bi-content columns">
    <div className="column primary-content is-two-fifths">
      <div>{title}</div>
      {content}
    </div>
    <div className="column secondary-content has-text-centered">
      <img width="600" src={image} alt="Art board" />
    </div>
  </section>
)

BiContent.propTypes = {
  image: PropTypes.string,
  content: PropTypes.node,
  title: PropTypes.node,
}

BiContent.defaultProps = {
  image: null,
  content: null,
  title: null,
}

export default BiContent
