import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

// import Button from "./button"
import CustomInput from "./custom-input"

import "../styles/contact-banner.scss"

const contactForm = (
  <form className="form">
    <CustomInput
      label="Correo Electrónico"
      type="email"
      name="email"
      placeholder="sergio@meiquer.mx"
    />
    <div className="columns">
      <div className="column">
        <CustomInput
          label="Nombre"
          type="text"
          name="name"
          placeholder="Sergio"
        />
      </div>
      <div className="column">
        <CustomInput
          label="Apellido"
          type="text"
          name="lastname"
          placeholder="Hernández"
        />
      </div>
    </div>
    <CustomInput
      label="¿Qué tipo de organización eres?"
      type="select"
      name="org"
      selectOptions={["Empresa", "Sociedad Civil", "Gobierno", "Escuela"]}
      placeholder="Empresa/Sociedad Civil/Gobierno/Escuela"
    />
    <CustomInput
      label="¿Qué estás buscando?"
      type="text"
      name="inquiry"
      placeholder="Un programa con el tema de mi organización..."
    />
  </form>
)

const AboutBanner = () => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      file(relativePath: { eq: "meiquer-pascua-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="contact-banner">
      <Img
        className="bg-image"
        fluid={data.file.childImageSharp.fluid}
        alt=""
        objectFit="cover"
      />
      <div className="banner-content columns">
        <div className="info column is-half">
          <div className="top">
            <h2 className="w-normal">
              <span className="c-turquoise w-bold">Juega</span>{" "}
              <span className="s-italic">en</span>{" "}
              <span className="w-bold">equipo</span>
            </h2>
            <p>
              ¿Quieres jugar con nosotros? Contáctanos para co-crear programas y
              actividades para transformar comunidades, ¡jugando!
            </p>
            <p>
              Envíanos un correo a{" "}
              <span className="w-bold">estación@meiquer.mx</span> para comenzar
              a colaborar.
            </p>
          </div>
          {/* <div className="columns">
            <div className="column is-half pl-0">
              <Button>
                <span>Contactar</span>
              </Button>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}

export default AboutBanner
