import React from "react"
import PropTypes from "prop-types"

import "../styles/concept-section.scss"

const ConceptSection = ({ concepts, title }) => (
  <section className="concept-section ">
    <div className="section-title">{title}</div>
    <div className="columns">
      {concepts.map((concept, idx) => (
        <div className="column has-text-centered" key={idx}>
          <div className="top">{concept.top}</div>
          <div className="content">{concept.content}</div>
          <div className="bottom">{concept.bottom}</div>
        </div>
      ))}
    </div>
  </section>
)

ConceptSection.propTypes = {
  concepts: PropTypes.array,
  title: PropTypes.node,
}

ConceptSection.defaultProps = {
  concepts: [],
  title: null,
}

export default ConceptSection
