import React from "react"
import PropTypes from "prop-types"

import Button from "./button"
import ConceptSection from "./concept-section"

import MeiquerLabsLogo from "../assets/logos/logo-meiquer-labs.svg"

const servicesTitle = (
  <h2 className="w-normal">
    ¿<span className="s-italic">Quieres</span>{" "}
    <span className="c-turquoise w-bold">jugar</span>{" "}
    <span className="s-italic">en</span> <span className="w-bold">equipo</span>?
  </h2>
)

const ContactOptions = ({ title, hideBottom }) => {
  const services = [
    {
      top: (
        <>
          <img
            style={{ width: "6em" }}
            src={MeiquerLabsLogo}
            alt="Meiquer Labs Logo"
          />
          <p style={{ letterSpacing: ".3rem" }} className="c-turquoise w-bold">
            Organizaciones
          </p>
        </>
      ),
      content: (
        <p>
          Construyendo programas, talleres o misiones, colaboramos para impactar
          jugando.
        </p>
      ),
      bottom: hideBottom ? null : (
        <Button linkTo="/contacto-ventas">Contactar</Button>
      ),
    },
    {
      top: (
        <>
          <img
            style={{ width: "6em" }}
            src={MeiquerLabsLogo}
            alt="Meiquer Labs Logo"
          />
          <p style={{ letterSpacing: ".3rem" }} className="c-turquoise w-bold">
            Empresas
          </p>
        </>
      ),
      content: (
        <p>
          Diseñando experiencias para tu equipo o sobre tu empresa dirigidas a
          niñas, niños y adultos.
        </p>
      ),
      bottom: hideBottom ? null : (
        <Button linkTo="/contacto-ventas">Contactar</Button>
      ),
    },
    {
      top: (
        <>
          <img
            style={{ width: "6em" }}
            src={MeiquerLabsLogo}
            alt="Meiquer Labs Logo"
          />
          <p style={{ letterSpacing: ".3rem" }} className="c-turquoise w-bold">
            Gobierno
          </p>
        </>
      ),
      content: (
        <p>
          Co-diseñando programas multidisciplinarios para niñas y niños
          transformando comunidades.
        </p>
      ),
      bottom: hideBottom ? null : (
        <Button linkTo="/contacto-ventas">Contactar</Button>
      ),
    },
    {
      top: (
        <>
          <img
            style={{ width: "6em" }}
            src={MeiquerLabsLogo}
            alt="Meiquer Labs Logo"
          />
          <p style={{ letterSpacing: ".3rem" }} className="c-turquoise w-bold">
            Escuelas
          </p>
        </>
      ),
      content: (
        <p>
          Diseñamos cursos STEAM, talleres y charlas extracurriculares para
          escuelas.
        </p>
      ),
      bottom: hideBottom ? null : (
        <Button linkTo="/contacto-ventas">Contactar</Button>
      ),
    },
  ]

  return <ConceptSection concepts={services} title={title ?? servicesTitle} />
}

ContactOptions.propTypes = {
  title: PropTypes.node,
  hideBottom: PropTypes.bool,
}

ContactOptions.defaultProps = {
  hideBottom: false,
}

export default ContactOptions
